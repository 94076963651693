
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"
import CascadeSelector from '@/views/Planning/components/CasvadeSelector.vue'
import { defineComponent, onMounted, ref, reactive, nextTick, h, computed, watch } from 'vue'
import { getGuidelineYear, getBottomUpList, saveBottomUp, refreshKufriBottomUp, refreshBudgetBu } from '@/API/planning'
import moment from 'moment'
import SpreadJsDialog from '@/views/Planning/components/SpreadJsDialog.vue'
import { message ,Modal} from "ant-design-vue";
import { formatDataForTable } from './components/spreadUtils'
// import { data } from './mock'

function generateKufirVersions(begin: string, end: string) {
  const versions = []
  const month = moment(begin.replace('K', ''))
  const endMonth = moment(end.replace('K', ''))
  while (!month.isAfter(endMonth)) {
    versions.push(month.format('YYYYKMM'))
    month.add(1, 'M')
  }

  return versions
}

const seasonConPoint2Fixed = (record: any) => {
  if (record.text || record.text === 0) {
    return record.text.toFixed(2) + '%'
  }
  return ''
}

function setId(data: any [], childrenKey = 'children', key = 'key') {
  let id = 1
  const setId = (data: any []) => {
    data.forEach((item: any) => {
      (item[key] as number) = id
      id++
      if (item[childrenKey] && (item[childrenKey]).length) {
        setId(item[childrenKey])
      }
    })
  }
  setId(data)
  return data
}

export default defineComponent({
  components: {
    CascadeSelector,
    SpreadJsDialog
  },
  setup() {
    const route: any = useRoute()
    const store = useStore()
    const scenarioId = route.query.scenarioId
    const planningId = route.query.planningId
    const scenarioName = route.query.scenarioName
    const lockStatus = route.query.lockStatus === 'true'
    const submitStatus = ref<boolean>(false)
    const topData = ref<any>({})
    const currentYear = ref<number>(route.query.year)
    const years = ref<number[]>([])
    const expandedRowKeysList = ref<any[]>([]) 
    const expandAllStatus = ref<boolean>(false)
    const resMakeList = ref<string []>([])
    const seasonTotal = [
      {
        title: 'annual'
      },
      {
        title: 'seasonOne'
      },
      {
        title: 'seasonTwo'
      },
      {
        title: 'seasonThree'
      },
      {
        title: 'seasonFour'
      }
    ]

    const seasons = [
      {
        title: 'Annual',
        slot: 'Annual',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'annual.releasePoint',
            key: 'annual.releasePoint',
            width: 80,
            customRender: seasonConPoint2Fixed
          },
          {
            title: 'Bottom Up',
            dataIndex: 'annual.buPoint',
            key: 'annual.buPoint',
            width: 80,
            customRender: seasonConPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q1',
        slot: 'Q1',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonOne.releasePoint',
            key: 'seasonOne.releasePoint',
            width: 80,
            customRender: seasonConPoint2Fixed
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonOne.buPoint',
            key: 'seasonOne.buPoint',
            width: 80,
            customRender: seasonConPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q2',
        slot: 'Q2',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonTwo.releasePoint',
            key: 'seasonTwo.releasePoint',
            width: 80,
            customRender: seasonConPoint2Fixed
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonTwo.buPoint',
            key: 'seasonTwo.buPoint',
            width: 80,
            customRender: seasonConPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q3',
        slot: 'Q3',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonThree.releasePoint',
            key: 'seasonThree.releasePoint',
            width: 80,
            customRender: seasonConPoint2Fixed
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonThree.buPoint',
            key: 'seasonThree.buPoint',
            width: 80,
            customRender: seasonConPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      },
      {
        title: 'Q4',
        slot: 'Q4',
        children: [
          {
            title: 'Guideline',
            dataIndex: 'seasonFour.releasePoint',
            key: 'seasonFour.releasePoint',
            width: 80,
            customRender: seasonConPoint2Fixed
          },
          {
            title: 'Bottom Up',
            dataIndex: 'seasonFour.buPoint',
            key: 'seasonFour.buPoint',
            width: 80,
            customRender: seasonConPoint2Fixed,
            customHeaderCell: () => {
              return {
                style: {
                  color: '#0088C6 !important'
                }
              }
            },
            customCell: () => {
              return {
                style: {
                  color: '#0088C6'
                }
              }
            }
          }
        ]
      }
    ]

    const months = ref<any []>(
      [
        {
          text: '01',
          number: '1',
          title: 'january',
          actualStatus: [],
          width: 60
        },
        {
          text: '02',
          number: '2',
          title: 'february',
          actualStatus: [],
          width: 60
        },
        {
          text: '03',
          number: '3',
          title: 'march',
          actualStatus: [],
          width: 60
        },
        {
          text: '04',
          number: '4',
          title: 'april',
          actualStatus: [],
          width: 60
        },
        {
          text: '05',
          number: '5',
          title: 'may',
          actualStatus: [],
          width: 60
        },
        {
          text: '06',
          number: '6',
          title: 'june',
          actualStatus: [],
          width: 60
        },
        {
          text: '07',
          number: '7',
          title: 'july',
          actualStatus: [],
          width: 60
        },
        {
          text: '08',
          number: '8',
          title: 'august',
          actualStatus: [],
          width: 60
        },
        {
          text: '09',
          number: '9',
          title: 'september',
          actualStatus: [],
          width: 60
        },
        {
          text: '10',
          number: '10',
          title: 'october',
          actualStatus: [],
          width: 60
        },
        {
          text: '11',
          number: '11',
          title: 'november',
          actualStatus: [],
          width: 60
        },
        {
          text: '12',
          number: '12',
          title: 'december',
          actualStatus: [],
          width: 60
        },
      ]
    ) 

    const tableColumns = ref<any []>(
      [
        {
          slots: { title: 'bu' },
          fixed: 'left',
          children: [
            {
              title: 'Brand',
              dataIndex: 'brand',
              width: 130,
              fixed: 'left'
            }
          ]
        },
        {
          slots: { title: 'typeClass' },
          fixed: 'left',
          children: [
            {
              title: 'Type Class',
              dataIndex: 'typeClass',
              width: 150,
            }
          ]
        },
        {
          slots: { title: 'nstGroup' },
          fixed: 'left',
          children: [
            {
              title: 'Model',
              dataIndex: 'nstGroup',
              width: 200,
            }
          ]
        },
        ...seasons.map(season => {
          return {
            slots: { title: season.title },
            width: 200,
            children: season.children
          }
        }),
        ...months.value.map(month => {
          return{
            slots: {
              title: month.title,
            },
            children: [
              {
                title: `${currentYear.value}-${month.text}`,
                slots: {
                  customRender: month.text,
                },
                width: 80
              }
            ],
          }
        })
      ]
    )

    const tableData = ref<any[]>([])

    const prePoint = ref<number>(0)

    const number2Fixed = computed(() => {
      return (val: any) => {
        if (val || val === 0) {
          return val.toFixed(2) + '%'
        }
        return ''
      }
    })

    const initMonthData = (obj: any, monthEn: string) => {
      if (!obj) {
        return {
          actualStatus: null,
          buPoint: null,
          budget: null,
          controllingPoint: null,
          dataInfoId: null,
          finalPoint: null,
          kufriVersion: null,
          month: months.value.filter(item => item.title === monthEn)[0].number,
          planningId: null,
          planningTypeId: null,
          releasePoint: null,
          rtLlp: null,
          rtVolume: null,
          submitPoint: null,
          wsLlp: null,
          wsVolume: null,
          year: currentYear.value
        }
      }
    }

    const methodFormatList = (list: any[]) => {
      if (!list) {
        return
      }
      const monthEns = months.value.map((month: any) => {
        return month.title
      })
      list.forEach((item: any, index: number) => {
        for(const key in item) {
          if (monthEns.includes(key)) {
            if (!item[key]) {
              item[key] = initMonthData(item[key], key)
            }
          }
          if (monthEns.includes(key)) {
            item[key].editing = false
          }
          if (key === 'children' && item[key]) {
            item[key].forEach((child: any) => {
              child.formatBrand = item.brand || item.mappingName?.split('-')[0]
              child.formatBu = item.bu
              child.formatTypeClass = item.typeClass
            })
            methodFormatList(item[key])
          }
        }
      })
    }

    const methodFormatSaveData = (make: any, type: string) => {
      const result: any[] = []
      const monthNames = months.value.map((month: any) => month.title)
      make.detailVOList.forEach((detail: any)=> {
        if (detail.children && detail.children.length > 0) {
          detail.children.forEach((child: any) => {
            for (const key in child) {
              let condition
              if (type === 'save') {
                condition = monthNames.includes(key) && !child[key].actualStatus && child[key].edited
              } else if (type === 'submit') {
                condition = monthNames.includes(key)
              }
              if (condition) {
                const object: any = {}
                object.controllingSavedPoint = child[key].controllingPoint
                object.controllingReleasedPoint = child[key].releasePoint
                object.id = child[key].dataInfoId
                object.buSavedPoint = child[key].buPoint
                object.buSubmitPoint = child[key].submitPoint

                if (!object.id) {
                  object.year = child[key].year
                  object.month = child[key].month
                  object.brand = child.formatBrand
                  object.typeClass = child.formatTypeClass
                  
                  object.nstGroup = child.nstGroup
                  object.nstGroupName = child.nstGroupName
                  object.bu = child.formatBu
                  object.actualStatus = false
                }
                if (type === 'save') {
                  result.push(object)
                } else {
                  if (object.id) {
                    result.push(object)
                  } else {
                    if (child[key].edited) {
                      result.push(object)
                    }
                  }
                }
              }
            }
          })
        }
      })
      return result
    }

    const methodChangeActual = () => {
      // 未来年全部forecast
      const nowYear = new Date().getFullYear()
      if (currentYear.value > nowYear) {
        months.value.forEach(month => {
          month.actualStatus = tableData.value.map((make: any) => {
            return false
          })
        })
      } else { // 当前年判断kufri
        months.value.forEach(month =>  {
          month.actualStatus = tableData.value.map((make: any) => {
            const kufriMonth = make.viewKufriVersion.substring(make.viewKufriVersion.length-2)
            return month.text < kufriMonth
          })
        })
      }
    }

    const methodGetList = async () => {
      const postData = {
        ...topData.value,
        scenrioId: scenarioId,
        year: currentYear.value
      }
      const responseList = await getBottomUpList(postData)
      const list = responseList.data || []
      resMakeList.value = list.map((item: any) => {
        return item.make
      })
      submitStatus.value = list.map((make: any) => {
        return make.submitStatus
      }).some((item: boolean) => {
        return item
      })
      // list = data
      tableData.value = list
      tableData.value.forEach(make => {
        make.kufirVersions = generateKufirVersions(make.startKufriVersion, make.currentKufriVersion)
        make.totalExpand = false
        make.viewKufriVersion = make.currentKufriVersion
        expandedRowKeysList.value.push([])
        if (!make.detailVOList) {
          make.detailVOList = []
        }
        methodFormatList(make.detailVOList)
        setId(make.detailVOList, 'children', 'id')
        nextTick(() => {
          resMakeList.value.forEach((make: string) => {
            const ele: any | null = document.querySelector(`.${make} .ant-table-body`)
            const title: any | null = document.querySelectorAll(`.${make} .scroll-content`)
            ele.addEventListener('scroll', function(e: any) {
              title.forEach((item: any) => {
                item.style.left = `-${e.target.scrollLeft}px`
              })
            })
          })
        })
      })
      methodChangeActual()
    }

    const initYears = async () => {
      const params = {
        bu: topData.value.bu,
        buId: topData.value.buId,
        scenrioId: scenarioId
      }
      const responseYears = await getGuidelineYear(params)
      years.value = responseYears.data
      years.value = responseYears.data.filter((year: any) => {
        return year.bottomActive
      })  
    }

    const handleUpdate = (condition: any) => {
      topData.value = condition
      methodGetList()
      initYears()
    }

    const handleKufriChange = async (kufri: string, makeIndex: number, make: any) => {
      const postData = {
        ...topData.value,
        scenarioId: scenarioId,
        planningId: make.planningId,
        year: currentYear.value,
        kufriVersion: kufri,
        bottomActive: true
      }
      const response = await refreshKufriBottomUp(postData)
      tableData.value[makeIndex].detailVOList = response.data[0].detailVOList || []
      methodFormatList(tableData.value[makeIndex].detailVOList)
      // 刷新对应kufri月份的acture状态
      methodChangeActual()
    }

    const handleTakeGuideline = (make: any) => {
      if (!make.releaseStatus) {
        return message.error("There's no data released from guideline.")
      }
      Modal.confirm({
        title: 'Tips',
        content: 'Note that take propose will overwrite existing data.',
        okText: 'Confirm',
        onOk() {
          const monthEns = months.value.map((month: any) => {
            return month.title
          })
          make.detailVOList.forEach((row: any) => {
            for(const key in row) {
              if (monthEns.includes(key)) {
                if (row[key].releasePoint !== row[key].buPoint) {
                  row[key].buPoint = row[key].releasePoint
                  row[key].edited = true
                }
              }
              if (key === 'children' && row.children) {
                row.children.forEach((child: any) => {
                  for (const key in child) {
                    if (monthEns.includes(key)) {                      
                      if (child[key].releasePoint !== child[key].buPoint) {
                        child[key].buPoint = child[key].releasePoint
                        child[key].edited = true
                      }                     
                    }
                  }
                })
              }
            }
          })
        },
        cancelText: 'Cancel',
      });
    }

    const handleChooseYear = (year: number) => {
      currentYear.value = year
      expandAllStatus.value = false
      // 刷新table header月份中的年份
      tableColumns.value = [
        {
          slots: { title: 'brand' },
          fixed: 'left',
          children: [
            {
              title: 'Brand',
              dataIndex: 'brand',
              width: 130,
              fixed: 'left'
            }
          ]
        },
        {
          slots: { title: 'typeClass' },
          fixed: 'left',
          children: [
            {
              title: 'Type Class',
              dataIndex: 'typeClass',
              width: 150,
            }
          ]
        },
        {
          slots: { title: 'nstGroup' },
          fixed: 'left',
          children: [
            {
              title: 'Model',
              dataIndex: 'nstGroup',
              width: 200,
            }
          ]
        },
        ...seasons.map(season => {
          return {
            slots: { title: season.title },
            width: 200,
            children: season.children
          }
        }),
        ...months.value.map(month => {
          return{
            slots: {
              title: month.title,
            },
            children: [
              {
                title: `${currentYear.value}-${month.text}`,
                slots: {
                  customRender: month.text,
                },
                width: 100
              }
            ],
          }
        })
      ]
      methodGetList()
    }

    // 点击单元格可编辑 item：单元格数据 record：整行数据
    const handleClickCell = (e: any, item: any, record: any) => {
      if (lockStatus) {
        return message.error('Data locked.')
      }
      if (submitStatus.value) {
        return message.error('Data already submitted,can not change.')
      }
      if (item.actualStatus || item.editDisabled) {
        return
      }
      item.editing = true
      prePoint.value = item.buPoint
      nextTick(()=>{
        const ele: any | null = document.querySelector('.data-table input')
        if (ele) {
          ele.focus()
        }
      })
    }

    // 获取焦点
    const handleFocus = (record: any, month: string) => {
      // 转化成小数
      if (record[month].buPoint) {
        record[month].buPoint = record[month].buPoint / 100
        console.log(record[month].buPoint)
      }
    }

    // 离焦事件 item: 单元格数据 record: 行数据 tableData:表格数据
    const handleBlur = (record: any, month: string, tableData: any) => {
      if (record[month].buPoint === 0 || record[month].buPoint === '0') {
        record[month].buPoint = 0
      } else {
        record[month].buPoint = Number(record[month].buPoint) || null
      }
      // 转化成百分数
      if (record[month].buPoint) {
        record[month].buPoint = record[month].buPoint * 100
      }
      if (record[month].buPoint !== prePoint.value) {
        record[month].edited = true
        // 是父节点 判断是否有子节点 如果有子节点 父节点编辑 子节点自动变成相同数值且不可编辑
        if (record.brand || record.typeClass) {
          if (record.children) {
            record.children.forEach((child: any) => {
              child[month].buPoint = record[month].buPoint
              child[month].edited = true
              child[month].editDisabled = !!child[month].buPoint
              child[month].empty = !child[month].buPoint
            })
          }
        }
        // 是子节点 找到父节点 父节点不可编辑
        if (!record.brand && !record.typeClass && record.nstGroup) {
          tableData.forEach((row: any) => {
            if (row.children) {
              const childControllingPointList: any[] = []
              row.children.forEach((child: any) => {
                childControllingPointList.push(child[month].buPoint)
                if (child[month].dataInfoId === record[month].dataInfoId) {
                  row[month].buPoint = null
                  row[month].editDisabled = true
                  row[month].empty = false
                }
              })
              // 所有child的controllingPoint都为null时 父节点可编辑
              const bool = childControllingPointList.every((item: any) => {
                return !item
              })
              if (bool) {
                row[month].editDisabled = false
              }
            }
          })
        }
      }
      record[month].editing = false
      if (!record[month].buPoint) {
        record[month].empty = true
      }
    }

    const handleForecast = (month: string, monthEn: string, index: number, make: any) => {
      if (lockStatus) {
        return message.error('Planning already locked')
      }
      if (submitStatus.value) {
        return message.error('Data already submitted,can not change.')
      }
      Modal.confirm({
        title: 'Tips',
        content: 'Note that take propose will overwrite existing data.',
        okText: 'Confirm',
        async onOk() {
          const params = {
            planningId: make.planningId,
            month,
            bu: topData.value.bu,
            buId: topData.value.buId,
            kufriVersion: make.currentKufriVersion
          }
          const response = await refreshBudgetBu(params)
          const budgetObject = response.data
          make.detailVOList.forEach((row: any) => {
            if (budgetObject) {
              row[monthEn].buPoint = budgetObject[`${row.mappingName}`] || null
            } else {
              row[monthEn].buPoint = null
            }
            if (row.children) {
              row.children.forEach((child: any) => {
                if (budgetObject) {
                  child[monthEn].buPoint = budgetObject[`${row.mappingName}`] || null
                } else {
                  child[monthEn].buPoint = null
                }
              })
            }
          })
        },
        cancelText: 'Cancel',
      });
      
    }

    const handleExpand = (expanded: any, record: any, index: number) => {
      if (expanded) {
        expandedRowKeysList.value[index]?.push(record.id)
      } else {
        const removeIndex = expandedRowKeysList.value[index].indexOf(record.id)
        if (index !== -1) {
          expandedRowKeysList.value[index].splice(removeIndex, 1)
        }
      }
      expandedRowKeysList.value[index]
    }

    watch(expandAllStatus, (value: boolean) => {
      store.commit(
        'updateSpinning',
        true
      )
      if (value) {
        tableData.value.forEach((make: any, index: number) => {
          expandedRowKeysList.value[index] = make.detailVOList.map((item: any) => {
            return item.id
          })
        })
      } else {
        expandedRowKeysList.value.forEach((expandedRowKeys: any) => {
          expandedRowKeys = expandedRowKeys.splice(0, expandedRowKeys.length)
        })
      }
      store.commit(
        'updateSpinning',
        false
      )
    })

    const handleExpandAll = () => {
      if (!expandAllStatus.value) {
        tableData.value.forEach((make: any, index: number) => {
          expandedRowKeysList.value[index] = make.detailVOList.map((item: any) => {
            return item.id
          })
          expandAllStatus.value = true
        })
      } else {
        expandedRowKeysList.value.forEach((expandedRowKeys: any) => {
          expandedRowKeys = expandedRowKeys.splice(0, expandedRowKeys.length)
        })
        expandAllStatus.value = false
      }
    }

    const handleSaveOrSubmit = async (type: string) => {
      if (lockStatus) {
        return message.error('Data locked.')
      }
      if (submitStatus.value) {
        return message.error('Data already submitted.')
      }
      const postData: any = {}
      const infoSaveDTOList: any[] = []
      tableData.value.forEach(make => {
        const makeItem: any = {}
        const resultList: any = methodFormatSaveData(make, type)
        makeItem.submitStatus = false
        if (type === 'submit') {
          resultList.forEach((item: any) => {
            item.buSubmitPoint = item.buSavedPoint
          })
        }
        makeItem.controllingSaveStatus = make.controllingSaveStatus
        makeItem.infoList = resultList
        makeItem.kufriVersion = make.currentKufriVersion || '2021K08'
        makeItem.make = make.make
        makeItem.planningId = make.planningId
        makeItem.submitStatus = type === 'submit' ? true : make.submitStatus
        makeItem.releaseStatus = make.releaseStatus
        makeItem.buSaveStatus = true
        infoSaveDTOList.push(makeItem)
      })
      postData.bu = topData.value.bu
      postData.buId = topData.value.buId
      postData.infoSaveDTOList = infoSaveDTOList
      await saveBottomUp(postData)
      message.success('Operation Succeeded')
      if (type === 'save' || type === 'submit') {
        expandAllStatus.value = false
        methodGetList()
      }
    }
    // onMounted(() => {
    //   tableData.forEach(make => {
    //     make.totalExpand = false
    //     expandedRowKeysList.push([])
    //     methodFormatList(make.detailVOList)
    //   })
    // })

    const spreadVisible = ref<boolean>(false)
    const spreadData = ref<any []>([])
    const actuals = ref<boolean[]>([])
    let currentMake: any = []
    const currentMakeName = ref<string>('')

    const handleBatchInput = (make: any, makeIndex: number) => {
      spreadVisible.value = true
      spreadData.value = make.detailVOList || []
      currentMake = make.detailVOList
      currentMakeName.value = make.make
      actuals.value = months.value.map(month => {
        return month.actualStatus[makeIndex]
      })
    }

    const handleSpreadJsUpdate = (dpreadJsData: any) => {
      formatDataForTable(currentMake, dpreadJsData, 'buPoint')
    }

    const handleCloseSpreadJsDialog = () =>  {
      spreadVisible.value = false
    }

    return {
      spreadVisible,
      spreadData,
      handleBatchInput,
      actuals,
      currentMakeName,
      handleSpreadJsUpdate,
      handleCloseSpreadJsDialog,
      
      scenarioName,
      topData,
      years,
      currentYear,
      prePoint,
      expandAllStatus,
      tableColumns,
      tableData,
      seasons,
      months,
      seasonTotal,
      expandedRowKeysList,
      number2Fixed,
      handleUpdate,
      handleKufriChange,
      handleTakeGuideline,
      handleChooseYear,
      handleClickCell,
      handleFocus,
      handleBlur,
      handleForecast,
      handleExpand,
      handleExpandAll,
      handleSaveOrSubmit
    }
  }
})
